<template>
  <v-layout v-if="loading" justify-center>
    <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    />
  </v-layout>
  <v-container v-else style="background-color:#fafafa !important;" fluid color-white>
    <v-card v-if="!isErrorParsingToken" class="mx-auto pb-3" max-width="450" elevation="0">
      <v-row class="ma-4" justify="center">
        <Logo class="my-5" />
        <v-col md="auto">
          <h2
            class="welcomeHeaderSubtext"
            justify="center">
            {{ userFirstName
              ? `Welcome, ${userFirstName}!`
              : `Welcome!` }}<br />
            Finish creating your Moody's Account.
          </h2>
          <h3 class="passDesc" justify="center">Password requirements:</h3>
          <ul>
            <li class="passReq">At least 8 characters</li>
            <li class="passReq">At least 1 lowercase letter (a-z)</li>
            <li class="passReq">At least 1 uppercase letter (A-Z)</li>
            <li class="passReq">At least 1 number (0-9)</li>
            <li class="passReq">Does not contain any part of your username</li>
          </ul>

          <v-row justify="center">
            <v-col cols="12" md="12">
              <v-form ref="form" v-model="isUserInputValid">
                <v-text-field
                  v-model="newPassword"
                  label="Enter New Password"
                  :rules="passwordRules"
                  required
                  :type="showNewPassword ? 'text' : 'password'"
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showNewPassword = !showNewPassword"
                />
                <v-text-field
                  v-model="confirmNewPassword"
                  label="Confirm New Password"
                  :rules="confirmPasswordRules"
                  required
                  :type="showConfirmPassword ? 'text' : 'password'"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                />
                <v-row class="mt-6" justify="center">
                  <v-btn
                    class="create-account white--text"
                    x-large
                    width="375"
                    height="50"
                    @click="savePassword"
                    :disabled="!isUserInputValid"
                    :color="isUserInputValid ? '#029AE1' : null"
                  >Create My Account</v-btn>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-card>

    <v-card
      v-else
      justify="center"
      class="mx-auto v-card v-sheet theme--light"
      width="500"
      height="250"
      elevation="0"
    >
      <v-row class="mt-12" justify="center">
        <v-col md="auto" justify="center" class="mt-12">
          <div class="ma-6">
            <h3>You are currently not authorized to perform this operation.</h3>
            <br/> 
            <h3>
              Please contact the administrator using our
              <a
                id="help"
                target="_blank"
                rel="noopener noreferrer"
                :href="appHelpPageUrl"
              >Help Page</a> for assistance with your account.
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-overlay :value="isOverlayOn">
      <v-progress-circular indeterminate v-if="!didPasswordSave" />
      <v-btn
        v-if="didPasswordSave"
        @click="isOverlayOn = false"
        height="fit-content"
        :class="isErrorBetweenServer? 'passwordAlert': ''"
        :color="isErrorBetweenServer? 'white': ''"
        :outlined="isErrorBetweenServer"
      >
        {{overlayMessage}}
        <v-icon small right>mdi-close</v-icon>
      </v-btn>
    </v-overlay>
  </v-container>
</template>


<script>
import Logo from '@/components/Logo';
import axios from 'axios';
import rules from '../utils/field-validations';

export default {
  name: "welcome",
  components: {
    Logo
  },
  data() {
    return {
      // Gets
      activeToken: this.getParam('activeToken'),
      stateToken: undefined,
      userFirstName: null,
      fromUri: this.getParam('fromURI'),
      appHelpPageUrl: `${window.location.origin}/help`,

      // UI
      loading: false,
      isOverlayOn: false,
      overlayMessage: '',
      isErrorBetweenServer: false,
      isErrorParsingToken: false,
      version: process.env.VUE_APP_VERSION,

      // Form validation
      newPassword: undefined,
      confirmNewPassword: undefined,
      showNewPassword: false,
      showConfirmPassword: false,
      passwordRules: [...rules.password],
      confirmPasswordRules: [
        ...rules.password,
        v => v === this.newPassword || "Passwords do not match"
      ],
      isUserInputValid: true,
      didPasswordSave: false,
    };
  },
  created() {
    this.verifyRecoveryToken();
  },
  methods: {
    getParam(searchKey) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(searchKey);
    },
    async verifyRecoveryToken() {
      if (!this.activeToken) {
        this.isErrorParsingToken = true;
      } else {
        this.loading = true;
        const bodyString = JSON.stringify({
          recoveryToken: this.activeToken
        });

        try {
          const { data } = await axios.post(
            `https://${process.env.VUE_APP_OKTA_DOMAIN}/api/v1/authn/recovery/token`,
            bodyString,
            { headers: {
                "Content-Type": "application/json",
            }}
          );

          this.stateToken = data.stateToken;
          this.userFirstName = data._embedded?.user?.profile?.firstName || '';
          this.loading = false;
        } catch (err) {
          this.isErrorParsingToken = true;
          this.overlayMessage = err.response?.data?.errorSummary
            ? err.response.data.errorSummary
            : 'Sorry, something went wrong in authenticating. Please contact our Help Desk.';
          this.isErrorBetweenServer = true;
          this.didPasswordSave = true;
          this.isOverlayOn = true;
          this.loading = false;
        }
      } 
    },
    async savePassword() {
      this.isOverlayOn = true;
      this.overlayMessage = '';
      this.didPasswordSave = false;
      this.isErrorBetweenServer = false;
      const bodyString = JSON.stringify({
        stateToken: this.stateToken,
        newPassword: this.newPassword
      });

      try {
        const { data } = await axios.post(
          `https://${process.env.VUE_APP_OKTA_DOMAIN}/api/v1/authn/credentials/reset_password`,
          bodyString,
          { headers: {
              "Content-Type": "application/json",
          }}
        );

        alert("Your account was created, please sign in.");

        if (this.fromUri) {
          const sessionToken = data.sessionToken;
          const redirectURL = `${this.fromUri}?sessionToken=${sessionToken}`;
          window.location.replace(redirectURL);
        } else {
          this.$router.replace('/login');
        }
      } catch (err) {
        this.overlayMessage = err.response?.data?.errorSummary
          ? err.response.data.errorSummary
          : `Sorry, something went wrong. Please contact our Help Desk. (version: ${this.version})`;
        this.isErrorBetweenServer = true;
        this.didPasswordSave = true;
        this.isOverlayOn = true;
      }
    }
  }
};
</script>
