import Vue from 'vue';
import Router from 'vue-router';
import OktaVue from '@okta/okta-vue';
import { OktaAuth } from '@okta/okta-auth-js';

import ImplicitCallbackPage from '@/views/ImplicitCallbackPage.vue';
import LoginComponent from '@/components/Login';
import Help from '@/views/HelpPage.vue';
import Welcome from '@/views/WelcomePage.vue';
import Home from '@/views/Home.vue';
import Error from '@/views/ErrorPage.vue';
import Discover from '@/views/DiscoverPage.vue';
import Settings from '@/views/Settings.vue';

import "./index.css";
import "@/assets/fonts/fonts.css";

Vue.use(Router);

const setTitle = (pageTitle) => `${pageTitle} | Moody's Account`;

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { title: setTitle("Home"), requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginComponent,
      meta: { title: setTitle("Login") }
    },
    {
      path: '/implicit/callback',
      component: ImplicitCallbackPage,
      meta: { title: setTitle("Authenticating...") }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      props: true,
      meta: { title: setTitle("Account Settings"), requiresAuth: true }
    },
    {
      path: '/discover',
      name: 'discover',
      component: Discover,
      props: true,
      meta: { title: setTitle("Discover"), requiresAuth: true }
    },
    {
      path: '/request',
      name: 'request',
      component: Error,
      props: true,
      meta: { title: setTitle("Request Access"), requiresAuth: true }
    },
    {
      path: '/help',
      name: 'help',
      component: Help,
      props: true,
      meta: { title: setTitle("Help"), requiresAuth: false }
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
      props: true,
      meta: { title: setTitle("Welcome"), requiresAuth: false }
    },
  ]
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export const authConfig = {
  clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
  issuer: `https://${process.env.VUE_APP_OKTA_DOMAIN}/oauth2/default`,
  redirectUri: window.location.protocol + '//' + window.location.host + '/implicit/callback',
  scopes: ['openid', 'profile', 'email', 'phone'],
  pkce: true,
};
const oktaAuth = new OktaAuth(authConfig);

Vue.use(OktaVue, {
  oktaAuth,
  onAuthRequired: () => {
    if (window.location.pathname !== '/login') {
      router.replace('/login');
    }
  },
});

export default router;
