export { CATCH_ALL_CONTACTS } from "./appListBase";
import { appListBase, contactsBase } from "./appListBase";


export const allApps = process.env.VUE_APP_ENV_KW === 'prod'
  ? appListBase.filter(app => app.prodEnabled)
    .map(app => {
      const { label, index, logo, desc, contacts, prodLinkUrl, prodId } = app;
      return {
        id: prodId,
        label, index, logo, desc, contacts,
        linkUrl: prodLinkUrl
      };
    })
  : appListBase.filter(app => app.devEnabled)
    .map(app => {
      const { label, index, logo, desc, contacts, devLinkUrl, devId } = app;
      return {
        id: devId,
        label, index, logo, desc, contacts,
        linkUrl: devLinkUrl
      };
    });

const getContacts = () => {
  const contactsMap = { ...contactsBase };
  allApps.forEach(app => contactsMap[app.label] = [ ...app.contacts ]);

  return contactsMap;
}

export const helpContactsEmails = getContacts();
