<template>
  <div>
    <Discover/>
  </div>
</template>

<script>
  import Discover from '@/components/Discover'

  export default {
    name: 'discover',
    components: {
      Discover
    }
  }
</script>
