<template>
  <v-row style="margin-left:30px;margin-top:20px">
    <v-col cols="8" class="mx-8 settingsTabs">
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-spacer/>
          <v-btn small outlined v-if="saved" @click="saved=false">Edit</v-btn>
          <v-btn small outlined color="green" :disabled="!valid" v-if="!saved" @click="updateProfile">Save</v-btn>
        </v-row>

        <v-text-field
          v-for="claim in claims"
          :key="claim.key"
          v-model="claim.value"
          :rules="claim.key===('mobilePhone') 
            ? phoneRules 
            : [v => !!v && !!v.trim() || `Field is required`]"
          :label="customFieldLabel(claim.key)"
          :disabled="saved"
        />
      </v-form>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate v-if="!saved && !error" />
        <v-btn
          class="passwordAlert"
          v-if="saved || error"
          @click="overlay=false"
          :color="error? 'white': ''"
          :outlined="error"
          height="fit-content"
        >
          {{overlayMessage}}&nbsp;
        </v-btn>
      </v-overlay>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import rules from '../utils/field-validations';

export default {
  name: "profile",
  data() {
    return {
      token: this.accessToken,
      user: this.userInfo,
      valid: true,
      editableFields: [
        { name: "firstName", label: "First Name *"},
        { name: "lastName", label: "Last Name *"},
        { name: "organization", label: "Company"},
        { name: "mobilePhone", label: "Mobile Number"},
      ],
      phoneRules: [...rules.phoneEmpty, ...rules.phone],
      saved: true,
      overlay: false,
      overlayMessage: undefined,
      claims: [],
      error: false,
      version: process.env.VUE_APP_VERSION,
    };
  },
  props: {
    accessToken: String,
    userInfo: Object,
  },
  watch: {
    "accessToken": function(newToken) {
      this.token = newToken;
    },
    "userInfo": function(newUser) {
      this.getClaims(newUser);
    },
    "overlay": function(status) {
      if (!status && !this.error && this.saved) {
        location.reload();
      }
    }
  },
  created() {
    this.getClaims(this.userInfo);
  },
  methods: {
    getClaims(userData) {
      this.user = userData;
      if(this.user) {
        this.claims = this.editableFields.map(({name}) => {
          return {
            key: name,
            value: userData[name]
          }
        });
      }
    },
    customFieldLabel(claim) {
      for (let field of this.editableFields) {
        if (field.name === claim) return field.label;
      }
      return claim;
    },
    async updateProfile() {
      this.saved = false;
      this.overlay = true;
      this.error = false;
      const payload = {
        profile: {
          firstName: this.claims[0].value || this.user.given_name,
          lastName: this.claims[1].value || this.user.family_name,
          organization: this.claims[2].value || this.user.organization,
          mobilePhone: this.claims[3].value || this.user.mobilePhone,
        }
      };

      try {
        if (!this.token) {
          this.token = await this.$auth.getAccessToken();
        }
        await axios.post(
          `${process.env.VUE_APP_SSO_API_URL}/users/${this.user.sub}?version=${this.version}`,
          JSON.stringify(payload),
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.$auth.getAccessToken()} ${this.version}`
            },
          }
        );
        this.overlayMessage = "Updated Successfully";
        this.saved = true;
      } catch (err) {
        this.error = true;
        if (err.response && err.response.data && err.response.data.message) {
          this.overlayMessage = err.response.data.message;
        } else if (err.message && err.message === 'Network Error') {
          this.overlayMessage = 'Sorry, you are not authorized to perform this operation.\n Please contact us for help';
        } else if (err.message) {
          this.overlayMessage = err.message
        } else {
          this.overlayMessage = 'Sorry, something went wrong, please try again later';
        }
      }
    },
  }
};
</script>