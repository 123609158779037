<template>
  <v-container fluid color-white class="mt-12">
    <v-row
      class="mt-12"
      justify="center"
      align="center"
    >
      <v-col md="auto" class="mt-12">
        <h3>
          Please submit a ticket to request access on the <a id='help' href='/help'>Help Page</a>.
        </h3>
        <v-row width="50%" justify="center">
          <v-btn
            @click="goToDash()"
            style="margin-bottom:150px"
            class="mt-6 mx-2 m2-12 v-btn v-btn--contained theme--light v-size--default primary"
          >Go to Discover Apps</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: 'request',
  methods: {
    goToDash() {
      this.$router.push({
        name: 'discover',
        params: {
          focusTab: 0
        }
      })
    }
  }
}
</script>
