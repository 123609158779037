<template>
  <v-menu
    offset-x offset-overflow
    open-on-hover
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <div class="my-2">
        <v-btn size="52" color="#09A1E0" fab small dark v-on="on">
          <span class="subtitle-1">{{initials}}</span>
        </v-btn>
      </div>
    </template>

    <v-card>
      <v-list v-if="userData">
        <v-list-item>
          <v-list-item-avatar
            color="#09A1E0" 
            size="52"
            fab
            small 
            dark
          >
            <span class="white--text subtitle-1 avatar-initials">{{initials}}</span>
          </v-list-item-avatar>
          
          <v-list-item-content>
            <v-list-item-title>{{fullName}}</v-list-item-title>
            <v-list-item-subtitle>{{userData.email}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list>
        <v-list-item>
          <v-btn 
            text 
            light 
            small 
            @click="showProfile"
          >
            Account Settings
          </v-btn>
        </v-list-item>

        <v-list-item>
          <v-btn 
            text 
            light
            small
            @click="logout"
          >
            Logout
          </v-btn>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
name: 'profile-button',
  data () {
    return {
      userData: this.userInfo,
    }
  },
  props: {
    userInfo: Object
  },
  watch: {
    "userInfo": function(newData) {
      this.userData = newData
    }
  },
  computed: {
    fullName() {
      if (!this.userData) {
        return '';
      }
      return this.formatName(this.userData.firstName) + ' ' + this.formatName(this.userData.lastName)
    },
    initials() {
      try {
        return this.userData.firstName[0] + this.userData.lastName[0];
      } catch (err) {
        return '';
      }
    }
  },
  methods: {
    formatName(str) {
      try {
        return str
          ? str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
          : ''
      } catch (err) {
        return str
      }
    },
    async logout() {
      await this.$auth.signOut('/login');
    },
    showProfile() {
      this.$router.push({
        name: 'settings',
        params: {
          focusTab: 0
        }
      })
    },
  }
}
</script>

<style scoped>
  .avatar-initials {
    margin: 0 auto;
  }
</style>
