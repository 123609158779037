<style scoped>
.chicklet-label {
  text-align: center;
  color: #a0a0a0;
  font-size: 12px;
  line-height: 13px;
}
.cardPos {
  position: initial !important;
}
::-webkit-scrollbar {
  display: none;
}
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  height: 190px;
}
.card {
  display: inline-block;
  background: white;
}
.scrollrow {
  display: inline !important;
}
</style>

<template>
  <div>

    <v-container fluid grid-list-xl>
      <div class="scrolling-wrapper ml-4">
        <v-row class="scrollrow">
          <v-card
            class="card pa-3"
            flat
            v-for="app in this.searchResultApps"
            :key="app.sortOrder"
            background="white"
          >
            <v-btn
              class="mx-3 pa-0"
              :width="chickletWidth"
              :height="chickletHeight"
              background="white"
              v-on:click="isDiscover ? goToRequest() : buttonClick(app.linkUrl)"
            >
              <v-list-item three-line>
                <div id="appContent">
                  <v-list-item-content class="ml-2 pt-0 pb-2">
                    <v-list-item-title
                      :class="(app.isSubscribed == true ) ? 'white--text' : '#09A1E0--text'"
                      class="cardTitle"
                    >{{app.label}}</v-list-item-title>
                    <v-list-item-subtitle
                      :class="(app.isSubscribed == true ) ? 'white--text' : '#09A1E0--text'"
                      id="cardSubtitle"
                      :title="app.desc"
                    >{{app.desc}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-card-actions v-if="isDiscover" id="cardActions">
                    <router-link tag="button" to="/request">
                      <v-btn id="cardActions" class="ml-2 mb-2" text small color="blue">
                        Request Access
                        <v-icon x-small left dark>mdi-arrow-right</v-icon>
                      </v-btn>
                    </router-link>
                  </v-card-actions>
                </div>
                <img 
                  width="95" 
                  height="170" 
                  class="pl-4 mr-0 float-right imgBorder" 
                  :src="app.logo || defaultLogo" 
                  :alt="app.label"
                />
              </v-list-item>
            </v-btn>
          </v-card>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      org: false,
      dialog: false,
      chickletWidth: "292",
      chickletHeight: "170",
      logoHeight: "33px",
      homeTab: null,
      chicklets: [],
      whatsMoving: false,
      search: "",
      allApps: this.apps,
      noResult: false,
      match: false,
      desc: "",
      appNames: [],
      longDesc: "",
      website: "",
      defaultLogo: require('@/assets/moodys-building.jpg'),
    };
  },
  props: {
    searchResultApps: Array,
    searchQuery: String,
    isDiscover: Boolean
  },
  methods: {
    buttonClick(href) {
      window.open(href, "_blank");
    },
    goToRequest() {
      this.$router.push({
        name: "request",
        params: {
          focusTab: 0
        }
      });
    },
  }
};
</script> 
