<template>
  <div>
    <v-container fluid color-white >
        <v-row 
            >

                <Help />

        </v-row>
      </v-container>

  </div>
</template>

<script>
  import Help from '@/components/Help'
  export default {
    name: 'help',
    components: {
      Help
    }
  }
</script>