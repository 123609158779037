<template>
  <v-app>
    <v-app-bar
      style="background-color:white !important"
      clipped-left
      fixed
      app
    >
      <v-app-bar-nav-icon v-if="authenticated" @click="toggleDrawer" />

      <v-toolbar-title class="headline text-uppercase">
        <router-link to="/">
          <Logo />
        </router-link>
      </v-toolbar-title>

      <v-spacer />

      <ProfileButton v-if="authenticated" :userInfo="userInfo" />
    </v-app-bar>

    <LeftNav 
      v-if="authenticated" 
      :drawer="drawer" 
      v-on:udpateDrawerState="udpateDrawer"
    />

    <v-main>
      <router-view 
        class="ml-4"
        :userInfo="userInfo"
        :apps="apps"
        :subscriptions="subscriptions"
        :visibleApps="visibleApps"
      />
      <FooterContent v-if="authenticated" class="mt-10" />
    </v-main>
  </v-app>
</template>


<script>
import axios from 'axios';

import LeftNav from '@/components/LeftNav';
import Logo from '@/components/Logo';
import ProfileButton from '@/components/ProfileButton';
import FooterContent from '@/components/FooterContent';

import { allApps } from './data/appListExporter';

export default {
  name: 'App',
  components: {
    LeftNav,
    Logo,
    ProfileButton,
    FooterContent,
  },
  data () {
    return {
      authenticated: false,
      userInfo: undefined,
      drawer: this.$vuetify.breakpoint.mdAndUp,
      apps: allApps,
      visibleApps: [],
      appNames: allApps.map(app => app.label),
      subscriptions: [],
      retriesCounter: 0,
      version: process.env.VUE_APP_VERSION,
    }
  },
  created () {
    this.setupUser()
  },
  watch: {
    '$route'(newRoute, oldRoute) {
      if (oldRoute.fullPath && oldRoute.fullPath !== newRoute.fullPath) {
        this.setupUser();
      }
    },
  },
  methods: {
    setupAdobeTag() {
      const adobeAnalyticsScript = document.createElement('script');
      adobeAnalyticsScript.setAttribute('src', process.env.VUE_APP_ADOBE_TAG_URL);
      adobeAnalyticsScript.setAttribute('id', 'adobe-tag');
      document.head.appendChild(adobeAnalyticsScript);
    },
    setupAdobeData(userData) {
      this.$digitalData.page = {
        PageInfo: {
          url: window.location.href,
          pageTitle: document.title,
          language: window.navigator.language
        },
        User: {
          UserID: userData.sub
        }
      }

      window.digitalData = this.$digitalData;
    },
    async setupUser() {
      try {
        this.authenticated = await this.$auth.isAuthenticated();
        if (this.authenticated && !this.userInfo) {
          if (this.$auth.getAccessToken()) {
            const userFromToken = await this.$auth.getUser();
            if (userFromToken) {
              this.getUserApps(userFromToken.sub);
              this.getUserInfo(userFromToken.sub);
              this.getAllApps();
              this.setupAdobeData(userFromToken);
              const existingTag = document.querySelector('#adobe-tag');
              if (!existingTag) {
                this.setupAdobeTag();
              }
            }
          } else if (this.retriesCounter < 5) {
            this.retriesCounter += 1;
            setTimeout(this.setupUser, this.retriesCounter * 500);
          } else {
            throw new Error();
          }
        }
      } catch(err) {
        await this.$auth.signOut('/login');
        alert(`Sorry, we had a problem logging you in. Please try to login again or contact us using the Help page. (version: ${this.version})`);
        this.retriesCounter = 0;
      }
    },
    async getAllApps() {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_SSO_API_URL}/apps?version=${this.version}`,
          { headers: {
            "Authorization": `Bearer ${this.$auth.getAccessToken()} ${this.version}`,
          }}
        );
        this.visibleApps = data.filter(app => {
          return app.status === "ACTIVE" && !app.visibility.hide.web;
        });
      } catch(err) {
        alert(`Sorry, we could not communicate with the server. Please try again later. (version: ${this.version})`)
      }
    },
    async getUserInfo(userId) {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_SSO_API_URL}/users/${userId}?version=${this.version}`,
          { headers: {
            Authorization: `Bearer ${this.$auth.getAccessToken()} ${this.version}`,
          }}
        );
        data.sub = userId;
        this.userInfo = data;
      } catch(err) {
        alert(`Sorry, we had a problem retrieving your data. Please try again later. (version: ${this.version})`);
      }
    },
    async getUserApps(userId) {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_SSO_API_URL}/apps/${userId}?version=${this.version}`,
          { headers: {
            Authorization: `Bearer ${this.$auth.getAccessToken()} ${this.version}`,
          }}
        );
        this.subscriptions = data.sort((a, b) => b.sortOrder - a.sortOrder)
      } catch(err) {
        alert(`Sorry, we had a problem retrieving your apps. Please try again later. (version: ${this.version})`);
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    udpateDrawer(state) {
      this.drawer = state;
    }
  }
}
</script>
