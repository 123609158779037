<template>
  <v-container id="helpPage">
    <div id="top">
      <v-btn id="backToLogin" text @click="goBack()" color>
        <v-icon x-small left dark>mdi-arrow-left</v-icon>Back
      </v-btn>
      <h1 class="header">How can we help?</h1>
    </div>

    <!-- HELP CARDS -->
    <div id="helpCardsContainer">
      <v-row align="center" justify="center" class="iepadding">
        <v-col class="helpCard">
          <v-hover>
            <v-card
              slot-scope="{ hover }"
              :class="`elevation-${hover ? 12 : 2}`"
              @click="handleClickHelpLink('help')"
            >
              <v-row class="helpCardContainer ma-2">
                <v-col class="helpIcon" align="left" justify="center">
                  <v-icon color="#43A1DA" x-large>
                    mdi-account-question-outline
                  </v-icon>
                </v-col>
                <v-col class="helpTextContainer">
                  <v-card-title class="helpText">Need help?</v-card-title>
                  <v-card-subtitle class="helpSubtext">
                    Let us know how we can assist you.
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>
        </v-col>
        <v-col class="helpCard">
          <v-hover>
            <v-card
              slot-scope="{ hover }"
              :class="`elevation-${hover ? 12 : 2}`"
              @click="handleClickHelpLink('access')"
            >
              <v-row class="helpCardContainer ma-2">
                <v-col class="helpIcon" align="left" justify="center">
                  <v-icon color="#43A1DA" x-large>
                    mdi-lock-open-check-outline
                  </v-icon>
                </v-col>
                <v-col class="helpTextContainer">
                  <v-card-title class="helpText">Request Access</v-card-title>
                  <v-card-subtitle class="helpSubtext">
                    We can help you get access for an application.
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>
        </v-col>
        <v-col class="helpCard">
          <v-hover>
            <v-card
              slot-scope="{ hover }"
              :class="`elevation-${hover ? 12 : 2}`"
              @click="handleClickHelpLink('report')"
            >
              <v-row class="helpCardContainer ma-2">
                <v-col class="helpIcon" align="left" justify="center">
                  <v-icon color="#43A1DA" x-large>
                    mdi-message-alert-outline
                  </v-icon>
                </v-col>
                <v-col class="helpTextContainer">
                  <v-card-title class="helpText">Send Feedback</v-card-title>
                  <v-card-subtitle class="helpSubtext">
                    Let us know how we can improve your experience.
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </div>
    <v-row>

      <!-- MAIN: WELCOME, FAQ, HOW-TOs -->
      <v-col cols="8">
        <v-card flat id="main" class="mr-6">
          <v-row class="ma-2">
            <v-col md="auto">
              <v-row>
                <v-col :class="`d-flex justify-start`" md="auto">
                  <h1 class="helpTitle">Welcome</h1>
                </v-col>
              </v-row>
              <v-card
                class="mx-auto pa-4 paragraphText"
                max-width="800"
                color="grey lighten-5"
                flat
              >
                <p>
                  Welcome to the Moody’s Single Sign On platform. You can now
                  easily sign in to all your applications through a single login
                  and password.
                </p>
                <p>
                  Once you sign in, you will either be taken into the
                  application directly or taken to the Moody’s Single Sign On
                  Dashboard depending on how you access the application.
                </p>
              </v-card>
              <h1 class="helpTitle" id="forget">Frequently Asked Questions</h1>
              <v-card
                class="mx-auto pa-4 paragraphText"
                max-width="800"
                color="grey lighten-5"
                flat
              >
                <p class="question">
                  What should I do if I forget my username or password?
                </p>
                <p class="answer">
                  Click Reset Password on your application sign-in page, enter
                  the email address you listed in your user account settings,
                  and click Send Email. Look in your inbox for the system
                  generated email and follow the prompts.
                </p>
              </v-card>
              <h1 class="helpTitle">How To's</h1>
              <h2 class="helpSubTitle" id="sign-in-app">
                Sign in to your Application
              </h2>
              <v-divider/>
              <v-card
                class="howTo pa-4 paragraphText"
                max-width="800"
                color="grey lighten-5"
                flat
              >
                <p>
                  1. Open a web browser and enter your
                  <b>application</b> web address. For example:
                </p>
                <a
                  href="http://pulse.moodysanalytics.com"
                  target="_blank"
                  class="link ml-4 mt-2"
                >
                  http://pulse.moodysanalytics.com
                </a>
                <br />
                <p class="mt-4">
                  2. Enter your email username and password, and then select Sign In.
                </p>
              </v-card>
              <h2 class="helpSubTitle" id="sign-in-dashboard">
                Sign in to your Dashboard
              </h2>
              <v-divider/>
              <v-card
                class="howTo pa-4 paragraphText"
                max-width="800"
                color="grey lighten-5"
                flat
              >
                <p>
                  1. Open a web browser and enter your
                  <b>dashboard</b> web address. For example:
                </p>
                <a
                  href="http://accounts.moodysanalytics.com"
                  target="_blank"
                  class="link ml-4 mt-2"
                >
                  http://accounts.moodysanalytics.com
                </a>
                <br />
                <p class="mt-4">
                  2. Enter your email username and password, and then select Sign In.
                </p>
              </v-card>
              <h2 class="helpSubTitle" id="report-security">
                Report a Security Issue
              </h2>
              <v-divider/>
              <v-card
                class="howTo pa-4 paragraphText"
                max-width="800"
                color="grey lighten-5"
                flat
              >
                <p>
                  Click <b><a @click="handleClickHelpLink('security')">here</a></b>
                  to report a security issue.
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- ARTICLE: CONTACT US -->
      <v-col cols="4">
        <v-card flat id="side" class="mr-6">
          <v-row class="ma-2">
            <v-col md="auto">
              <h1 class="helpTitle">Contact Us</h1>
              <p>For assistance, please contact Client Services:</p>
              <p class="paragraphText .body-1 mb-0 text-blue darken">Americas: {{ contactUsNumbers.americas }}</p>
              <p class="paragraphText .body-1 mb-0 text-blue darken">EMEA: {{ contactUsNumbers.emea }}</p>
              <p class="paragraphText .body-1 mb-0 text-blue darken">Asia Pacific: {{ contactUsNumbers.asiaPacific }}</p>
              <p class="paragraphText .body-1 mb-0 text-blue darken">Japan: {{ contactUsNumbers.japan }}</p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

    </v-row>

    <!-- HELP CONTACT MODAL -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Send Message</span>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pt-0 pb-0 mt-0 mb-0">
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="8" class="ma-0 px-4 pa-0">
                  <v-text-field
                    v-model="senderName"
                    label="Name:"
                    required
                    :rules="validations.name"
                  />
                </v-col>
                <v-col cols="12" sm="8" class="ma-0 px-4 pa-0">
                  <v-text-field
                    v-model="senderEmail"
                    label="Email:"
                    required
                    :rules="validations.email"
                  />
                </v-col>
                <v-col cols="12" sm="8" class="ma-0 px-4 pa-0">
                  <v-text-field
                    v-model="senderPhone"
                    label="Phone Number:"
                    required
                    :rules="
                      senderPhone === null || senderPhone === ''
                        ? validations.phoneEmpty
                        : validations.phone
                    "
                  />
                </v-col>
                <v-col cols="12" sm="8" class="ma-0 px-4 pa-0">
                  <v-text-field
                    v-model="senderCompany"
                    label="Company:"
                    required
                    :rules="validations.company"
                  />
                </v-col>
                <v-col cols="12" sm="8" class="ma-0 px-4 pa-0">
                  <v-autocomplete
                    :items="countryList"
                    label="Country:"
                    v-model="senderCountry"
                    required
                    :rules="validations.country"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="10" class="ma-0 px-4 pa-0">
                  <v-autocomplete
                    :items="appOptions"
                    label="Which product is this in reference to?"
                    v-model="selectedProduct"
                    required
                    :rules="validations.product"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="!requestAccess" cols="12" sm="10" class="ma-0 px-4 pa-0">
                  <v-autocomplete
                    :items="setHelpItems()"
                    label="What do you need help with?"
                    v-model="selectedHelpTopic"
                    required
                    :rules="validations.selectedHelpTopic"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" class="ma-0 px-4 pa-0">
                  <v-textarea
                    v-model="senderMessage"
                    autocomplete="Message"
                    label="Message"
                    :rules="validations.message"
                    required
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeMessageModal()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            :disabled="!valid"
            text
            @click="handleSubmitForm()"
          >
            Send Message
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- HELP CONTACT MODAL SUBMISSION RESPONSE -->
    <v-dialog v-model="successModal" max-width="540px">
      <v-card>
        <v-card-title>
          <span class="headline" color="blue darken-1">Success!</span>
        </v-card-title>
        <v-card-text>
          <h2 class="font-weight-light mt-4">Your message has been sent successfully, thank you.</h2>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeSuccessModal()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>


<script>
import emailjs from 'emailjs-com';

import contactUsNumbers from '../data/contact-us-numbers';
import countryList from '../data/country-list';
import { allApps, CATCH_ALL_CONTACTS, helpContactsEmails } from '../data/appListExporter';
import validations from '../utils/field-validations';

// DEV: To-Do: Transfer to environment vars
const EMAILJS_SERVICE_ID = "default_service"
const EMAILJS_TEMPLATE_ID = "sso_dashboard_help";
const EMAILJS_USER_ID = "user_u93LgEaaJTDXchJzBFohT";

export default {
  name: "help",
  data() {
    return {
      contactUsNumbers,
      countryList,
      validations,
      appOptions: this.getAppOptions(),
      helpTopicGroups: {
        'help': [
          "General inquiries",
          "Questions about my dashboard",
          "Cannot log in"
        ],
        'access': ["Request access to an application"],
        'report': [
          "General feedback",
          "Report a bug",
          "Report a potential security issue"
        ],
        'security': ["Report a potential security issue"]
      },
      version: process.env.VUE_APP_VERSION,

      currentHelpTopicGroup: null,
      requestAccess: false,
      valid: true,
      dialog: false,
      successModal: false,

      senderName: null,
      senderEmail: null,
      senderPhone: null,
      senderCountry: null,
      senderCompany: null,
      selectedHelpTopic: null,
      selectedProduct: [],
      senderMessage: null,
    };
  },
  methods: {
    goBack() {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        const promptMessage = 'There is no previous page. Would you like to close this tab?'
        if (window.confirm(promptMessage)) close();
      }
    },
    getAppOptions() {
      const appOptions = allApps.map(app => app.label)
        .sort((a, b) => a.localeCompare(b));

      return [
        "Single Sign On Dashboard",
        ...appOptions,
        "... My product isn't listed here"
      ];
    },
    handleClickHelpLink(helpTopicGroup) {
      this.requestAccess = helpTopicGroup === 'access';
      this.dialog = true;
      this.currentHelpTopicGroup = helpTopicGroup;
    },
    setHelpItems() {
      return this.helpTopicGroups[this.currentHelpTopicGroup];
    },
    closeMessageModal() {
      this.dialog = false;
      this.recipients = [];
      this.$refs.form.reset();
    },
    closeSuccessModal() {
      this.successModal = false;
    },
    async handleSubmitForm() {
      this.$refs.form.validate();
      this.dialog = false;

      const sanitizeString = (input, regex) => {
        return regex !== undefined
          ? input.trim().replace(regex, "")
          : input.trim();
      };

      const importedHelpContactEmails = helpContactsEmails[this.selectedProduct] || [];
      const senderParams = {
        senderName: sanitizeString(this.senderName, /[^A-Za-z0-9-&., ]/g),
        senderEmail: sanitizeString(this.senderEmail),
        senderPhone: sanitizeString(this.senderPhone),
        senderCompany: sanitizeString(this.senderCompany, /[^A-Za-z0-9-&., ]/g),
        senderCountry: this.senderCountry,
        selectedProduct: this.selectedProduct,
        selectedHelpTopic: this.currentHelpTopicGroup === 'access'
          ? "Request Access"
          : this.selectedHelpTopic,
        senderMessage: sanitizeString(this.senderMessage, /[^A-Za-z0-9-_.,:?!"'/$&@ ]/g),
        productContactEmails: this.currentHelpTopicGroup === 'report'
          ? [
            ...CATCH_ALL_CONTACTS
          ]
          : [
            ...importedHelpContactEmails,
            ...CATCH_ALL_CONTACTS
          ]
      };

      await this.sendMessage(senderParams);
    },
    async sendMessage(templateParams) {
      try {
        await emailjs.send(
          EMAILJS_SERVICE_ID,
          EMAILJS_TEMPLATE_ID,
          templateParams,
          EMAILJS_USER_ID
        );
        this.successModal = true;
        this.recipients = [];
        this.$refs.form.reset();
      // eslint-disable-next-line
      } catch(error) {
        window.alert(`Sorry, your message failed to send. Please try again later. (version: ${this.version})`);
      }
    }
  }
};
</script>
