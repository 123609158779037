<template>
  <div class="home" data-cy='home-page-container'>
    <Chicklets :subscriptions="subscriptions" :visibleApps="visibleApps" :userInfo="userInfo"/>
  </div>
</template>

<script>
  import Chicklets from '@/components/Chicklets'

  export default {
    name: 'home',
    components: {
      Chicklets
    },
    props: {
      subscriptions: Array,
      visibleApps: Array,
      userInfo: Object,
    }  
  }
</script>
