<template>
  <div>
    <v-navigation-drawer
      :permanent="drawerState && $vuetify.breakpoint.mdAndUp"
      clipped
      app
      v-model="drawerState"
      color='white'
      :width="210"
    >
      <v-list
        dense
        nav
        class="py-0"
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.route"
          link
          active-class="light-blue--text"
          v-on:click="setActive(item)"
        >
          <v-list-item-icon />
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-avatar v-if="item.title === activeIndex.title" tile color="light-blue" width="8" min-width="8" height="36"/>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        drawerState: this.drawer,
        activeIndex: "",
        items: [
          { title: 'My Apps', route:'/' },
          { title: 'Discover Apps', route:'/discover' },
        ],
        miniVariant: false,
        expandOnHover: false,
        background: false, 
      }
    },
    props: {
      drawer: Boolean,
    },
    watch: {
      "drawer" : function(state) {
        this.drawerState = state
      },
      "drawerState": function(state) {
        this.$emit("udpateDrawerState", state)
      }
    },
     methods: {
      setActive(index) { 
        this.activeIndex = index;
      },
    },
  }
</script>