<template>
  <v-card flat>
    <v-footer style="background-color:white !important" class="font-weight-medium mt-12">
      <v-list-item>
        <v-list-item-content justify="center" align="center">
          <v-img
            class="footerimg mb-4"
            lazy-src="@/assets/mlogo.png"
            height="30"
            max-width="140"
            src="@/assets/mlogo.png"
          ></v-img>
          <v-list-item-subtitle class="mb-2">
            <a
              class="footerLink text--secondary"
              href="https://www.moodys.com/termsofuseinfo.aspx?lang=en&cy=global"
              target="_blank"
            >Terms of Service</a>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="mb-2">
            <a
              class="footerLink text--secondary"
              href="https://www.moodys.com/privatepolicy.aspx?lang=en&cy=global"
              target="_blank"
            >Privacy Policy</a>
          </v-list-item-subtitle>

          <router-link class="footerLink" to="/help">
            <v-list-item-subtitle class="mb-2 footerLink">Help</v-list-item-subtitle>
          </router-link>

          <v-list-item-subtitle class="mb-2">
            v{{version}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-footer>
  </v-card>
</template>


<script>
export default {
  name: "footer-content",
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    }
  },
};
</script>
