<template>
  <v-col cols="8" class="mx-8">
    <h3
      class="passDesc"
      justify="center"
    >New passwords must meet the following requirements:</h3>
    <ul class="mb-6">
      <li class="passReq">At least 8 characters</li>
      <li class="passReq">At least 1 lowercase letter (a-z)</li>
      <li class="passReq">At least 1 uppercase letter (A-Z)</li>
      <li class="passReq">At least 1 number (0-9)</li>
      <li class="passReq">Does not contain any part of your username</li>
    </ul>

    <v-form ref="form" v-model="valid">
      <v-text-field
        v-model="currentPassword"
        label="Current Password"
        :rules="currentPasswordRules"
        required
        :type="showCurrentPassword ? 'text' : 'password'"
        :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showCurrentPassword = !showCurrentPassword"
      />
      <v-text-field
        v-model="newPassword"
        label="New Password"
        :rules="passwordRules"
        required
        :type="showNewPassword ? 'text' : 'password'"
        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showNewPassword = !showNewPassword"
      />
      <v-text-field
        v-model="confirmNewPassword"
        label="Confirm New Password"
        :rules="confirmPasswordRules"
        required
        :type="showConfirmPassword ? 'text' : 'password'"
        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showConfirmPassword = !showConfirmPassword"
      />
      <v-row class="ml-1 mt-4">
        <v-btn
          small
          outlined
          @click="save"
          :disabled="!valid || this.newPassword !== this.confirmNewPassword"
          :color="valid ? 'green' : null"
        >Change Password</v-btn>
      </v-row>
    </v-form>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate v-if="!saved" class="passwordAlert" />
      <v-btn
        class="passwordAlert"
        v-if="saved"
        @click="overlay=false"
        :color="error? 'white': ''"
        :outlined="error"
        height="fit-content"
      >
        {{overlayMessage}}&nbsp;
        <v-icon small v-if="error">mdi-close</v-icon>
      </v-btn>
    </v-overlay>
  </v-col>
</template>

<script>
import axios from "axios";
import rules from '../utils/field-validations';

export default {
  name: "change-password",
  data() {
    return {
      valid: true,
      currentPassword: undefined,
      newPassword: undefined,
      confirmNewPassword: undefined,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      currentPasswordRules: [v => !!v || "Current Password is required"],
      passwordRules: [...rules.password],
      confirmPasswordRules: [
        ...rules.password,
        v => v === this.newPassword || "Passwords do not match"
      ],
      overlay: false,
      saved: false,
      overlayMessage: undefined,
      error: false,
      token: this.accessToken,
      user: this.userInfo,
      version: process.env.VUE_APP_VERSION,
    };
  },
  props: {
    accessToken: String,
    userInfo: Object,
  },
  watch: {
    "accessToken": function(newToken) {
      this.token = newToken;
    },
    "userInfo": function(newUserInfo) {
      this.user = newUserInfo;
    }
  },
  methods: {
    async save() {
      this.saved = false;
      this.error = false;
      const payload = {
        oldPassword: { value: this.currentPassword },
        newPassword: { value: this.newPassword }
      };
      this.overlayMessage = undefined;
      this.overlay = true;
      try {
        if (!this.token) {
          this.token = await this.$auth.getAccessToken();
        }
        await axios.post(
          `${process.env.VUE_APP_SSO_API_URL}/users/${this.user.sub}/credentials/password?version=${this.version}`,
          JSON.stringify(payload),
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.$auth.getAccessToken()} ${this.version}`
            },
          }
        )
        this.overlayMessage = "Password Updated Successfully";
        this.saved = true;
        this.$refs.form.reset();
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          if (err.response.data.message.includes('403')) {
            this.overlayMessage = 'Wrong Old Password or New Password has been used recently';
          } else {
            this.overlayMessage = err.response.data.message;
          }
        } else {
          err.message 
            ? this.overlayMessage = err.message
            : this.overlayMessage = 'Sorry, something went wrong, please try again later';

        }
        this.error = true;
        this.saved = true;
      }
    }
  }
};
</script>