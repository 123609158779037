<template>
  <div>
    <v-container fluid color-white class="mt-12">
      <v-row 
        class="mt-12"
        justify="center"
      >
        <Welcome/>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import Welcome from '@/components/Welcome'
  export default {
    name: 'welcome',
    components: {
      Welcome
    }
  }
</script>
