<template>
    <v-toolbar light color="grey lighten-4">
      <v-spacer></v-spacer>
            <v-autocomplete
              style="box-shadow:0 0 0 0.5pt #C0C0C0 !important;border-color: #464646 !important"
              prepend-inner-icon="mdi-magnify"
              color="#464646"
              dark
              flat
              hide-details
              :items="sortApps"
              dense
              solo-inverted
              clearable
              outlined
              label="Search for an App..."
              v-model="query"
              noDataText="No results found"
              @input="onSearchSubmition"
              @keyup.enter="onSearchSubmition"
              @keyup.delete="onSearchSubmition"
              @click="onSearchSubmition"
            ></v-autocomplete>
      <v-spacer></v-spacer>

       <v-row justify="center">

    </v-row>
    </v-toolbar>

</template>

<script>
export default {
    data() {
      return {
        query: null,
      };
    },
    props: {
      apps: Array,
    },
    computed: {
      sortApps() {
        return [...this.apps].sort((a, b) => a.localeCompare(b));
      }
    },
    methods: {
      onSearchSubmition(event) {
        this.$emit('search-submitted', this.query, event);
      },
    },
}
</script>
