<template>
    <v-container fluid>
        <v-card :width="924" :height="500" style="margin-bottom:40px; padding-top: 40px">
        <v-row></v-row>
        <v-tabs vertical color="#09A1E0">
            <v-tab key="profile">Profile</v-tab>
            <v-tab key="security">Password</v-tab>
            <v-tab-item key="profile">
                <Profile :accessToken="token" :userInfo="user" />
            </v-tab-item>
            <v-tab-item key="security">
                <ChangePassword :accessToken="token" :userInfo="user"/>
            </v-tab-item>
        </v-tabs>
        </v-card>
    </v-container>
</template>

<script>
import Profile from '@/components/Profile'
import ChangePassword from '@/components/ChangePassword'

export default {
    name: 'settings',
    data() {
        return {
            token: this.accessToken,
            user: this.userInfo,
        }
    },
    components: {
        Profile,
        ChangePassword
    },
    props: {
        accessToken: String,
        userInfo: Object,
    },
    watch: {
        "accessToken": function(newToken) {
            this.token = newToken;
        },
        "userInfo": function(newUserInfo) {
            this.user = newUserInfo;
        }
  },
}
</script>