import "core-js/stable";
import "regenerator-runtime/runtime";
import router from '@/router';
import vuetify from './plugins/vuetify';
import VueGtag from "vue-gtag";

import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false;

// Google Analytics implementation
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
}, router);

// Adobe Analytics method
Vue.prototype.$digitalData  = { 
  page: {
    PageInfo: {
      url: '',
      pageTitle: '',
      language: ''
    },
    User : {
      UserID: ''
    }
  }
}

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
