export const CATCH_ALL_CONTACTS = [ "sso@moodys.com" ];

export const contactsBase = {
  "Single Sign On Dashboard": [
    // "sarrah.bang@moodys.com",
  ],
  "... My product isn't listed here": [
    "clientservices@moodys.com",
  ],
};

export const appListBase = [
  {
    label: 'Know Your Supplier (KYS)',
    index: 0,
    prodId: '0oa92zhuasf35k4ry4x6',
    devId: '0oa92nmocWDxxbs8a4x6',
    logo: require('@/assets/corpulse.jpg'),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://kys.moodysanalytics.com",
    devLinkUrl: "https://dev.kys.moodysanalytics.com/login",
    desc: "Free for COVID-19 for healthcare workers to help hospitals and other healthcare providers identify and screen suppliers of medical equipment.",
    contacts: [
      "tberger@rdc.com",
    ],
  },
  {
    label: 'Compliance Pulse',
    index: 1,
    prodId: '',
    devId: '0oa5bcmh3s9ahqNEf4x6',
    logo: require('@/assets/pulse.png'),
    prodEnabled: false,
    devEnabled: false,
    prodLinkUrl: "https://maa-adv.modus-qa.com",
    devLinkUrl: "https://maa-adv.modus-qa.com",
    desc:"Moody's Analytics introduces an intelligent compliance risk solution that automates and enhances customer onboarding and due diligence processes.",
    contacts: [
      "matthew.davis-non-empl@moodys.com",
    ],
  },
  {
    label: 'QUIQProperty',
    index: 2,
    prodId: '0oacu1mlvVmcq8Bxs4x6',
    devId: '0oaay5bn1DwW5Gn6H4x6',
    logo: require('@/assets/reis.png'),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://quiqproperty.moodysanalytics.com/okta/login",
    devLinkUrl: "http://quiq-property-dev-1183104703.us-west-2.elb.amazonaws.com/okta/login",
    desc: "QUIQProperty automates the digitization and normalization of rent roll data, ingesting tenant data in unstructured formats and seamlessly translating this data into structures that support property cash flow analysis.",
    contacts: [
      "darren.stovel@moodys.com",
      "dev.patel@moodys.com",
    ],
  },
  {
    label: 'Coronavirus Pulse',
    index: 3,
    prodId: '0oae7lappcuogRp7i4x6',
    devId: '0oadyzd6zqlNPGb6d4x6',
    logo: require('@/assets/cp.png'),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://login.moodysanalytics.com/home/bookmark/0oa5lnf60lT0UzrSk4x7/2557",
    devLinkUrl: "https://dev-login.moodysanalytics.com/home/bookmark/0oa5lnbf9yem2rawU4x7/2557",
    desc: "Coronavirus Pulse presents a curated list of links to real-time, external, third-party, coronavirus-related news stories based on sentiment.",
    contacts: [
      "matthew.davis-non-empl@moodys.com",
    ],
  },
  {
    label: "Moody's Analytics Customer Portal",
    index: 4,
    prodId: '0oa1amh2w6lMKH16p4x7',
    devId: '0oa3rubserY0WCXxl4x7',
    logo: require('@/assets/CustPortal.png'),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://moodys-analytics.force.com/customers",
    devLinkUrl: "https://dev-login.moodysanalytics.com/home/bookmark/0oa3rubserY0WCXxl4x7/2557",
    desc: "The Moody's Analytics SFDC website that provides information about applications to which customers subscribe.",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  {
    label: 'API Portal',
    index: 5,
    prodId: '0oao159bj2B5WMpHK4x6',
    devId: '0oadci1h3JzSQJPAd4x6',
    logo: require('@/assets/api.png'),
    prodEnabled: false,
    devEnabled: false,
    prodLinkUrl: "https://developer.moodysanalytics.com/login",
    devLinkUrl: "https://dev-moodysanalytics.devportal.apigee.io/",
    desc: "Moody's new API developer portal includes documentation for various products and APIs across the organization. Learn about how to use Moody's APIs and get support.",
    contacts: [
      "sarrah.bang@moodys.com",
    ],
  },
  {
    label: 'ML Model Catalog',
    index: 6,
    prodId: '0oaqr56zyGR2YUrTH4x6',
    devId: '0oap3kmsua69gUi5d4x6',
    logo: require('@/assets/ML.png'),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://demo.ml.moodysanalytics.com",
    devLinkUrl: "https://demo.ml.moodysanalytics.com",
    desc: "Explore Moody's new Machine Learning Model Catalog for various financial use cases. Learn about how to use Moody's ML Models and get support.",
    contacts: [
      "lawrence.gabriel@moodys.com",
      "matthew.davis-non-empl@moodys.com",
    ],
  },
  {
    label: 'Financial Statement Quality Score',
    index: 7,
    prodId: '0oa123kukzLKBdM834x7',
    devId: '0oaqd1dkoOcH2JFKk4x6',
    logo: require('@/assets/fsqs.png'),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://ers.mlfabric.moodysanalytics.com",
    devLinkUrl: "https://ers.mlfabric.moodysanalytics.com",
    desc: "A framework powered by AI, machine learning, big data, and accounting principles which provide a calculated score for financial statements.",
    contacts: [
      "min.wu1@moodys.com",
      "Jiansong.Chen@moodys.com",
    ],
  },
  { 
    label: 'ImpairmentStudio', 
    index: 8,
    prodId: '0oa1am3p79vV3EDow4x7',
    devId: '0oa169adbtJaVW2yw4x7',
    logo: require('@/assets/Impairment.png'), 
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://rafa.moodysanalytics.com/federatedlogin?referrerurl=https://rafa.moodysanalytics.com/impairmentstudio",
    devLinkUrl: "https://qa.rafa.moodysanalytics.net/federatedlogin?referrerurl=https://qa.rafa.moodysanalytics.net/impairmentstudio",
    desc: "A cloud-based solution which calculates expected credit losses under the Current Expected Credit Loss (CECL) and IFRS 9 accounting standards.",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  { 
    label: 'CAP',
    index: 9,
    prodId: '0oa1am2p27VOxQKvk4x7',
    devId: '0oa169ehmalvgoIL84x7',
    logo: require('@/assets/CAP.png'), 
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://rafa.moodysanalytics.com/federatedlogin?referrerurl=https://rafa.moodysanalytics.com/cap",
    devLinkUrl: "https://qa.rafa.moodysanalytics.net/federatedlogin?referrerurl=https://qa.rafa.moodysanalytics.net/cap",
    desc: "The CAP solution is a gateway for Risk Management professionals to gain direct access to Moody’s Analytics industry-leading models, data, and expertise.",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  { 
    label: 'Capital Risk Analyzer', 
    index: 10,
    prodId: '0oa1am45cc10wSgYg4x7',
    devId: '0oa169dxoiH3YiGON4x7',
    logo: require('@/assets/CRA.png'), 
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://rafa.moodysanalytics.com/federatedlogin?referrerurl=https://rafa.moodysanalytics.com/cra",
    devLinkUrl: "https://qa.rafa.moodysanalytics.net/federatedlogin?referrerurl=https://qa.rafa.moodysanalytics.net/cra",
    desc: "A strategic tool that addresses stress testing and accounting requirements by projecting key capital ratios and credit metrics for business and economic scenarios.",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  { 
    label: "RFS Portal for Internal Users", 
    index: 11,
    prodId: '0oa1e9pgqhALSgCQG4x7',
    devId: '0oa3ls7if0jRMwae14x7',
    logo: require('@/assets/salesforce.png'), 
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://login.moodysanalytics.com/home/bookmark/0oa1e9pgqhALSgCQG4x7/2557",
    devLinkUrl: "https://dev-login.moodysanalytics.com/home/bookmark/0oa3ls7if0jRMwae14x7/2557",
    desc: "This is a shortcut to access the RAFA portal for Internal users.",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  {
    label: "Information Web",
    index: 12,
    prodId: '0oa1eh5ao24z9MyJm4x7',
    devId: '0oaecbysuu3sdsaF44x6',
    // logo: require("@/assets/mlcat.png"), // from prod
    logo: require("@/assets/infoweb.png"), // from dev
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://information.moodysanalytics.com",
    devLinkUrl: "https://dev.infoweb.analytics.moodys.net",
    desc: "A publication site providing access to a variety of Enterprise Risk Solutions research and product information per a user's product license(s).",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  {
    label: "REIS",
    index: 13,
    prodId: '0oa264j1jiTnvak914x7',
    devId: '0oaz2eaar3y0Z0tV14x6',
    logo: require("@/assets/reis.png"),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://cre.reis.com/?success=true",
    devLinkUrl: "https://qa-maa.reis.com/?success=true",
    desc: "REIS is the leading commercial real estate data source in the nation, providing an advantage to firms with debt or equity exposure across capital markets.",
    contacts: [
      "ma_reis_info@moodys.com",
    ],
  },
  {
    label: "Economy.com",
    index: 14,
    prodId: '0oa1wg72brgVpPUhf4x7',
    devId: '0oa1rzfis1T423NKc4x7',
    logo: require("@/assets/economy.png"),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://www.economy.com/account/login",
    devLinkUrl: "https://qa.economy.com/account/login",
    desc: "Economy.com is a source of economic solutions and analysis from Moody's Analytics.",
    contacts: [
      "help@economy.com",
    ],
  },
  {
    label: "CMM",
    index: 15,
    prodId: '0oa389siyvabuXyGS4x7',
    devId: '0oa31us6cw701wGba4x7',
    logo: require("@/assets/CAP.png"),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://login.moodysanalytics.com/home/bookmark/0oa389siyvabuXyGS4x7/2557",
    devLinkUrl: "https://dev-login.moodysanalytics.com/home/bookmark/0oa31us6cw701wGba4x7/2557",
    desc: "A powerful commercial real estate analysis solution that brings together industry-leading market data and default analytics.",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  {
    label: "CreditEdge",
    index: 16,
    prodId: '0oa389u1jd3TZnReN4x7',
    devId: '0oa31ut5f35eIdlpM4x7',
    logo: require("@/assets/CRA.png"),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://login.moodysanalytics.com/home/bookmark/0oa389u1jd3TZnReN4x7/2557",
    devLinkUrl: "https://dev-login.moodysanalytics.com/home/bookmark/0oa31ut5f35eIdlpM4x7/2557",
    desc: "Moody’s Analytics provides financial intelligence and analytical tools to help business leaders make better, faster decisions.",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  {
    label: "Data Alliance Portal",
    index: 17,
    prodId: '0oa389viqywpWw5td4x7',
    devId: '0oa387uzm0YV5neNa4x7',
    logo: require("@/assets/CustPortal.png"),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://login.moodysanalytics.com/home/bookmark/0oa389viqywpWw5td4x7/2557",
    devLinkUrl: "https://dev-login.moodysanalytics.com/home/bookmark/0oa387uzm0YV5neNa4x7/2557",
    desc: "The Data Alliance Portal is a centralized platform for all Data Alliance membership activities providing members access to portfolio analysis and benchmarking results.",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  {
    label: "ImpairmentCalc",
    index: 18,
    prodId: '0oa389yelnm86VL614x7',
    devId: '0oa31utf1hviRBbAv4x7',
    logo: require("@/assets/Impairment.png"),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://login.moodysanalytics.com/home/bookmark/0oa389yelnm86VL614x7/2557",
    devLinkUrl: "https://dev-login.moodysanalytics.com/home/bookmark/0oa31utf1hviRBbAv4x7/2557",
    desc: "A cloud-based offering to help clients satisfy International Financial Reporting Standards 9 (IFRS 9) impairment requirements based on an expected credit loss (ECL) model incorporating forward- looking perspectives on provisions.",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  {
    label: "RiskBench",
    index: 19,
    prodId: '0oa389z2qdkP7SF5c4x7',
    devId: '0oa31ux20q0pQ0uL34x7',
    logo: require("@/assets/CRA.png"),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://login.moodysanalytics.com/home/bookmark/0oa389z2qdkP7SF5c4x7/2557",
    devLinkUrl: "https://dev-login.moodysanalytics.com/home/bookmark/0oa31ux20q0pQ0uL34x7/2557",
    desc: "An innovative tool for business users that provides credit risk metrics, benchmark data, portfolio analytics and industry insight",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  {
    label: "RiskCalc",
    index: 20,
    prodId: '0oa389ye2dYK8OwUf4x7',
    devId: '0oa31uw1sqc2KzBCG4x7',
    logo: require("@/assets/CRA.png"),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://login.moodysanalytics.com/home/bookmark/0oa389ye2dYK8OwUf4x7/2557",
    devLinkUrl: "https://dev-login.moodysanalytics.com/home/bookmark/0oa31uw1sqc2KzBCG4x7/2557",
    desc: "A comprehensive default and recovery risk solution for assessing private firms and financial institutions.",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  {
    label: "China Structured Finance Portal",
    index: 21,
    prodId: '0oa3v8muoyXE16WYi4x7',
    devId: '0oa2yevtvd7TgHx6o4x7',
    logo: '',
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://login.moodysanalytics.com/home/bookmark/0oa43yyk0g3nTlARq4x7/2557",
    devLinkUrl: "https://dev-login.moodysanalytics.com/home/oidc_client/0oa2yevtvd7TgHx6o4x7/aln177a159h7Zf52X0g8",
    desc: "The China Structured Finance Portal offers China market data and analytics across all structured finance asset classes with advanced reporting and time-saving data normalization and aggregation.",
    contacts: [
      "chinasfportalsupport@moodys.com",
    ],
  },
  {
    label: 'RiskFrontier',
    index: 22,
    prodId: '0oa5cxka59vJxAwPR4x7',
    devId: '0oa59jjx03YsDx20n4x7',
    logo: require("@/assets/economy.png"),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://login.moodysanalytics.com/home/bookmark/0oa5cxka59vJxAwPR4x7/2557",
    devLinkUrl: "https://dev-login.moodysanalytics.com/home/bookmark/0oa59jjx03YsDx20n4x7/2557",
    desc: "Credit portfolio risk management solution that helps users understand portfolio risk dynamics and manage concentration risk.",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  {
    label: 'Banking Cloud',
    index: 23,
    prodId: '0oa5civxzn7DAxbcR4x7',
    devId: '0oa59jkd72xbaL6oW4x7',
    logo: require("@/assets/economy.png"),
    prodEnabled: true,
    devEnabled: true,
    prodLinkUrl: "https://login.moodysanalytics.com/home/bookmark/0oa5civxzn7DAxbcR4x7/2557",
    devLinkUrl: "https://dev-login.moodysanalytics.com/home/bookmark/0oa59jkd72xbaL6oW4x7/2557",
    desc: "Banking Cloud Credit Risk is a solution. It provides a comprehensive, streamlined, and cost-efficient regulatory compliance solution.",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
  {
    label: 'PortfolioStudio',
    index: 24,
    prodId: '0oa5cxt7og5fj6myB4x7',
    devId: '0oa5ccgfq6faNNWNo4x7',
    logo: require("@/assets/economy.png"),
    prodEnabled: false,
    devEnabled: true,
    prodLinkUrl: "https://login.moodysanalytics.com/home/bookmark/0oa5cxt7og5fj6myB4x7/2557",
    devLinkUrl: "https://dev-login.moodysanalytics.com/home/bookmark/0oa5ccgfq6faNNWNo4x7/2557",
    desc: "Combines portfolio analytics and decision enabling tools to effectively identify, measure, and manage risk.",
    contacts: [
      "clientservices@moodys.com",
    ],
  },
];
