<style scoped>
.chicklet-label {
  text-align: center;
  color: #a0a0a0;
  font-size: 12px;
  line-height: 13px;
}
.cardPos {
  position: initial !important;
}
#cardSubtitle {
  -webkit-line-clamp: 8;
  line-clamp: 8;

  max-height: 8.25rem; /* line-clamp fallback for older browsers */
  line-height: 1rem; /* line-clamp fallback for older browsers */
}
</style>

<template>
  <v-container class="appContainer" style="margin-bottom:150px" fluid grid-list-xl>
    <SearchBar defaultQuery="okta" :apps="appNames" v-on:search-submitted="searchQuery"/>
    <div v-if="searchResult">
      <v-btn v-if="searchResult" class="ml-2 mt-6" id="backToLogin" text @click="clearSearch()" color>
        <v-icon x-small left dark>mdi-arrow-left</v-icon>Back
      </v-btn>   
      <div>
        <SearchResult :searchResultApps="filteredApps" :isDiscover="false"  :searchQuery="search" />
      </div>
    </div>

    <div v-else>
      {{ getUserApps }}
      <draggable
        class="d-flex flex-wrap pa-12 v-card cardPos v-sheet theme--light"
        v-model="chicklets"
        @end="moveComplete"
        :move="movingAction"
      >
        <v-card flat class="pa-3" v-for="chicklet in chicklets" :key="chicklet.label">
          <v-btn
            class="mx-3 pa-0"
            :width="chickletWidth"
            :height="chickletHeight"
            v-on:click="buttonClick(chicklet.linkUrl)"
          >
            <v-list-item three-line class="align-start">
              <div id="appContent">
                <v-list-item-content class="ml-2">
                  <v-list-item-title class="cardTitle">{{chicklet.label}}</v-list-item-title>
                  <v-list-item-subtitle id="cardSubtitle" :title="chicklet.desc">{{chicklet.desc}}</v-list-item-subtitle>
                </v-list-item-content>
              </div>
              <img
                width="95"
                height="180"
                class="pl-4 mr-0 float-right imgBorder"
                :src="chicklet.logo || defaultLogo"
                :alt="chicklet.label"
              />
            </v-list-item>
          </v-btn>
        </v-card>
      </draggable>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import SearchBar from "@/components/Searchbar";
import SearchResult from "@/components/SearchResult";

import { allApps } from '../data/appListExporter';

export default {
  data() {
    return {
      chickletWidth: "292",
      chickletHeight: "180",
      logoHeight: "33px",
      homeTab: null,
      chicklets: [],
      appNames: [],
      draggedTile: null,
      search: "",
      noResult: false,
      match: false,
      desc: "",
      filteredApps: undefined,
      searchResult: false,
      userId: "",
      defaultLogo: require('@/assets/moodys-building.jpg'),
    };
  },
  components: {
    draggable,
    SearchBar,
    SearchResult,
  },
  props: {
    subscriptions: Array,
    visibleApps: Array,
    userInfo: Object,
  },
  async created() {
    const { data } = await axios.get(`https://${process.env.VUE_APP_OKTA_DOMAIN}/api/v1/users/me/home/tabs`, {
      withCredentials: true
    });
    this.homeTab = data[0].id;
  },
  computed: {
    getUserApps() {
      const appsTracker = {}
      for (let app of allApps) {
        appsTracker[app.id] = app;
      }

      const appsList = this.subscriptions
        .filter(app => appsTracker[app.appInstanceId])
        .map(app => ({
          ...appsTracker[app.appInstanceId],
          instanceId: app.id,
          sortOrder: app.sortOrder,
        }));
      appsList.sort((a, b) => a.sortOrder - b.sortOrder);

      this.updateChicklets(appsList);
      return '';
    }
  },
  methods: {
    updateChicklets(apps) {
      this.chicklets = [...apps];
      this.appNames = apps.map(app => app.label);
    },
    clearSearch(){  
      this.searchResult = false
    },
    async searchQuery(query, event) {
      //have to re initialize this.chicklets as full list after
      this.search = query;
      var searchString = this.search;
      if (!searchString || (event !== undefined && event.keyCode === 8)) {
        this.searchResult = false;
        return;
      }
      searchString = searchString.trim().toLowerCase();
      this.filteredApps = this.chicklets.filter(app => {
        return searchString === app.label.toLowerCase();
      });
      this.searchResult = true;
    },
    buttonClick(href) {
      window.open(href, "_blank");
    },
    movingAction(event) {
      this.draggedTile = {
        app: event.draggedContext.element, 
        sortIndex: event.draggedContext.futureIndex
      };
    },
    moveComplete() {
      axios.put(
        `https://${process.env.VUE_APP_OKTA_DOMAIN}/api/v1/users/me/home/tabs/${this.homeTab}/items/${this.draggedTile.app.instanceId}`,
        { sortOrder: this.draggedTile.sortIndex },
        { withCredentials: true }
      );
      this.draggedTile = null;
    }
  }
};
</script>
