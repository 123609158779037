<style scoped>
.chicklet-label {
  text-align: center;
  color: #a0a0a0;
  font-size: 12px;
  line-height: 13px;
}
.cardPos{
  position: initial !important;
}
#cardSubtitle {
  -webkit-line-clamp: 7;
  line-clamp: 7;

  max-height: 7.25rem; /* line-clamp fallback for older browsers */
  line-height: 1rem; /* line-clamp fallback for older browsers */
}
</style>

<template>
  <v-container class="appContainer" fluid grid-list-xl>
    <SearchBar defaultQuery="okta" :apps="appNames" v-on:search-submitted="searchQuery"/>
    <div v-if="searchResult">
      <v-btn
        v-if="searchResult"
        class="ml-2 mt-6"
        id="backToLogin"
        text
        @click="clearSearch()"
        color
      >
        <v-icon x-small left dark>mdi-arrow-left</v-icon>Back
      </v-btn>
      <div class="d-flex flex-wrap pa-12 v-card cardPos v-sheet theme--light">
        <SearchResult :searchResultApps="filteredApps" :isDiscover="true" :searchQuery="search"/>
      </div>
    </div>

    <div v-else class="d-flex flex-wrap pa-12 v-card cardPos v-sheet theme--light">
      <v-card
        flat class="pa-3"
        v-for="app in this.chicklets"
        :key="app.sortOrder"
      >
        <v-btn
          class="mx-3 pa-0"
          :width="chickletWidth"
          :height="chickletHeight"
          to='/request'
        >
          <v-list-item three-line class="align-start">
            <div id="appContent">
              <v-list-item-content class="ml-2">
                <v-list-item-title class="cardTitle">{{app.label}}</v-list-item-title>
                <v-list-item-subtitle
                  id="cardSubtitle"
                  :title="app.desc">{{app.desc}}</v-list-item-subtitle>
                <v-card-actions id="cardActions">
                  <span id="cardActions" class="requestAccessButton">Request Access <v-icon x-small left dark>mdi-arrow-right</v-icon></span>
                </v-card-actions>
              </v-list-item-content>
            </div>
            <img 
              width=95 
              height=180 
              class="pl-4 mr-0 float-right imgBorder" 
              :src="app.logo || defaultLogo" 
              :alt="app.label"
            />
          </v-list-item>
        </v-btn>
      </v-card>

      <v-card flat
        class="mx-3 mt-2"
        :width="chickletWidth"
      />
    </div>
  </v-container>
</template>


<script>
import SearchBar from '@/components/Searchbar';
import SearchResult from '@/components/SearchResult';

import { allApps } from '../data/appListExporter';

export default {
  data() {
    return {
      chickletWidth: "292",
      chickletHeight: "180",
      logoHeight: "33px",
      homeTab: null,
      chicklets: [...allApps],
      appNames: allApps.map(app => app.label),
      defaultLogo: require('@/assets/moodys-building.jpg'),
      search: "",
      searchResult: false,
      filteredApps: undefined,
    }
  },
  components: {
    SearchBar,
    SearchResult
  },
  methods: {
    clearSearch() {
      this.searchResult = false;
    },
    async searchQuery(query, event) {
      //have to re initialize this.chicklets as full list after
      this.search = query;
      var searchString = this.search;
      if (!searchString || (event !== undefined && event.keyCode === 8)) {
        this.searchResult = false;
        return;
      }
      searchString = searchString.trim().toLowerCase();
      this.filteredApps = this.chicklets.filter(app => {
        return searchString === app.label.toLowerCase();
      });
      this.searchResult = true;
    },
    buttonClick(href) {
        window.open(href, '_blank')
    }
  }
}
</script>
