<template>
  <div>
    <v-container fluid color-white class="mt-12">
        <v-row class="mt-12"
            justify="center"
            >

                <Error/>

        </v-row>
      </v-container>

  </div>
</template>

<script>
  import Error from '@/components/Error'

  export default {
    name: 'error',
    components: {
      Error
    }
  }
</script>
