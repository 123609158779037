<template>
  <div class="login">
    <v-layout v-if="loading" justify-center>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      />
    </v-layout> 
    <div id="okta-signin-container" :class="loading ? 'invisible' : ''"/>
  </div>
</template>


<script>
import OktaSignIn from "@okta/okta-signin-widget";

import { authConfig } from "../router";

import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import "./signin.css";

const CE = (tag) => document.createElement(tag);
const QS = (location, str) => location.querySelector(str);
const QSA = (location, str) => location.querySelectorAll(str);
const setAttributes = (targetEl, attributesObj) =>
  Object.keys(attributesObj).forEach(key => targetEl.setAttribute(key, attributesObj[key]));
const setStyles = (targetEl, stylesObj) =>
  Object.keys(stylesObj).forEach(key => targetEl.style[key] = stylesObj[key]);

export default {
  name: "login",
  data() {
    return {
      widget: null,
      loading: true,
      selectedCountry: "",
      version: process.env.VUE_APP_VERSION,
    }
  },
  async beforeMount() {
    const config = {
      authParams: {
        responseType: "code",
        grantType: "authorization_code",
        issuer: authConfig.issuer,
        scopes: authConfig.scopes,
        display: "page",
        responseMode: "query"
      },
      baseUrl: authConfig.issuer.split("oauth2")[0],
      clientId: authConfig.clientId,
      redirectUri: authConfig.redirectUri,
      features: { registration: true, idpDiscovery: true },
      logo: "logo-ma.svg",
      helpLinks: {
        help: "/help",
        custom: [
          {
            text: "Terms of Service",
            href: "https://www.moodys.com/termsofuseinfo.aspx?lang=en&cy=global",
            target: "_blank"
          },
        ]
      },
      registration: {
        parseSchema: function(schema, onSuccess) {
          onSuccess(schema);
        },
        preSubmit: function(postData, onSuccess) {
          onSuccess(postData);
        },
        postSubmit: function(response, onSuccess) {
          onSuccess(response);
        }
      },
    };

    this.widget = new OktaSignIn(config);

    const exists = await this.widget.authClient.session.exists()
    if (exists) {
      const { accessToken } = await this.widget.authClient.tokenManager.getTokens()
      if(!accessToken) {
        const res = await this.widget.authClient.token.getWithoutPrompt()
        this.saveTokenAndRedirect(res.tokens)
      } else {
        this.redirect()
      }
    } else {
      this.loading = false;
      this.widget.renderEl(
        { el: '#okta-signin-container' }, 
        this.widgetSuccessCallback, 
        this.widgetErrorCallback
      );
    }
  },
  mounted() {
    this.widget.on("afterRender", (context) => {
      switch (context.controller) {
        case "primary-auth": {
          this.renameDefaultLabels("label[for=okta-signin-username]", 'Email');
          this.customizeFooterLinks();
          break;
        }
        case "forgot-password": {
          this.renameDefaultLabels("label[for=account-recovery-username]", 'Email');
          break;
        }
        case "registration": {
          this.disableSubmit();
          this.customizeSignUpForm();
          this.addValidationListeners();
          break;
        }
      }
    });
  },
  destroyed: function() {
    this.widget.remove();
  },
  methods: {
    redirect() {
      window.location.replace(window.location.protocol + '//' + window.location.host)
    },
    saveTokenAndRedirect(tokens) {
      this.widget.authClient.tokenManager.setTokens(tokens)
      this.redirect()
    },
    async widgetSuccessCallback(res) {
      this.loading = true
      const exists = await this.widget.authClient.session.exists()
      if (exists) {
        this.saveTokenAndRedirect(res.tokens)
      } else {
        this.loading = false;
      }
    },
    widgetErrorCallback() {
      alert(`Sorry, we had a problem with the authentication server. Please contact us using our Help page. (version: ${this.version})`);
    },
    renameDefaultLabels(cssSelector, value) {
      const usernameLable = QS(document, cssSelector);
      if (usernameLable.innerText !== value) {
        usernameLable.innerText = value;
      }
    },
    customizeFooterLinks() {
      const defaultHelpLinksBlock = QS(document, "ul#help-links-container");
      defaultHelpLinksBlock.style.display = "block";
      QS(defaultHelpLinksBlock, "a[data-se=forgot-password]").innerText = "Reset Password";
      QS(defaultHelpLinksBlock, "a.js-help-link").target = "_self"; // "Help link"

      const defaultHelpLinks = QSA(defaultHelpLinksBlock, "a");
      for (let i = 0; i < defaultHelpLinks.length; i++) {
        const linkNode = defaultHelpLinks[i];
        const linkClass = linkNode.className;

        if (!linkClass.includes("word-link")) {
          linkNode.className = `${linkClass} word-link`;
        }
      }
    },
    disableSubmit() {
      const registerButton = QS(document, "input[type=submit]");
      registerButton.disabled = true;
      setStyles(registerButton, {
        opacity: ".3",
        cursor: "default"
      });
    },
    enableSubmit() {
      const registerButton = QS(document, "input[type=submit]");
      registerButton.disabled = false;
      setStyles(registerButton, {
        opacity: 1,
        cursor: "pointer"
      });
    },
    validateForm() {
      /**
       * This validation function SUPPLEMENTS server-side email and password validation
       * and is not meant to be a catch-all, specifically for those invalidities
       * */

      const isFormValid = () => {
        const inputsContainer = QS(document, ".o-form-fieldset-container");
        const passwordInput = QS(inputsContainer, "input[type=password]");
        const textInputs = QSA(inputsContainer, "input[type=text][aria-label]");

        const areAnyInputsEmpty = [passwordInput, ...textInputs].some(el => el.value.trim() === "");
        const errorMessages = QS(inputsContainer, ".o-form-input-error");
        const isCountrySelected = !["", "Select One"].some(invalidValue => {
          return this.selectedCountry === invalidValue;
        });
        const areTermsAgreedUpon = QS(inputsContainer, "#termsCheckbox").checked;

        return (
          !areAnyInputsEmpty &&
          !errorMessages &&
          isCountrySelected &&
          areTermsAgreedUpon
        );
      }

      isFormValid()
        ? this.enableSubmit()
        : this.disableSubmit();
    },
    createTermsInput() {
      if (QS(document, "#termsCheckboxLabel")) return null;

      const newTermsCheckbox = CE("input");
      newTermsCheckbox.className = "added-boxinput--checkbox";
      setAttributes(newTermsCheckbox, {
        type: "checkbox",
        id: "termsCheckbox",
      });

      const newTermsPLink = CE("a");
      newTermsPLink.innerText = "Terms of Use";
      newTermsPLink.className = "word-link";
      setAttributes(newTermsPLink, {
        title: "Terms of Use",
        href: "https://www.moodys.com/termsofuseinfo.aspx?lang=en&cy=global",
        target: "_blank",
      });

      const newTermsP = CE("p");
        newTermsP.append("I agree to Moody's ", newTermsPLink, ". *");

      const newTermsLabel = CE("label");
      newTermsLabel.className = "required-fields-label added-boxinput--label";
      newTermsLabel.setAttribute("id", "termsCheckboxLabel");
      newTermsLabel.append(newTermsCheckbox, newTermsP);

      return newTermsLabel;
    },
    customizeSignUpForm() {
      const inputsContainer = QS(document, ".o-form-fieldset-container");

      const countryInputRow = QSA(document, ".o-form-fieldset")[5];
      if (!!countryInputRow && !countryInputRow.className.includes("country-row-fieldset")) {
        countryInputRow.className += " country-row-fieldset";
        QS(countryInputRow, "label").innerHTML += "*&nbsp;";
      }
      QS(countryInputRow, ".chzn-container-single").style.width = "inherit";

      const asteriskExplanation = QS(inputsContainer, "span.required-fields-label");
      if (asteriskExplanation && !asteriskExplanation.className.includes("asterisk-explanation")) {
        asteriskExplanation.className += " asterisk-explanation";
      }

      const newTermsLabelInputBlock = this.createTermsInput();

      inputsContainer.append(
        newTermsLabelInputBlock,
        asteriskExplanation
      );
    },
    addValidationListeners() {
      const container = QS(document, ".o-form-fieldset-container");
      container.addEventListener("input", ({ target }) => {
        const accepted = new Set([ "text", "checkbox", "password" ]);
        if (accepted.has(target.type)) {
          this.validateForm();
        }
      });

      const countryInput = QS(QS(document, ".chzn-single"), "span");
      countryInput.addEventListener("DOMSubtreeModified", () => {
        const countryInputValue = countryInput.innerText;
        this.selectedCountry = countryInputValue;
        this.validateForm();
      });
    }
  },
};
</script>


<style lang="scss">
.invisible {
  visibility: hidden !important;
}

#okta-sign-in.auth-container {
  /**
   *  This #okta-sign-in.auth-container will wrap the remaining css because the native vue
   *  style "scope" attribute is limited by the widget import so it's omitted.
   *  Also, to add specificity.
   */

  overflow: hidden;
  width: 450px;
  height: 100%;

  a.word-link, a[data-se=back-link] {
    &:link, &:visited {
      color: #1662dd;
    }
    &:active, &:hover {
      text-decoration: underline;
    }
  }

  .auth-header {
    z-index: unset;
  }

  // color of text between text inputs and register button
  .country-row-fieldset label,
  .required-fields-label p,
  .asterisk-explanation {
    color: #a7a7a7;
  }

  .country-row-fieldset {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    padding-top: 2px;

    word-wrap: normal;

    .o-form-label {
      padding: 0 0 0 8px;
      font-weight: normal;
    }

    .o-form-input {
      padding-left: 20px;
    }

    .chzn-container-single {
      text-align: left;
    }
  }

  .added-boxinput {
    &--label {
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      padding-left: 8px;

      p {
        margin-right: 10px;
      }
    }

    &--checkbox[type=checkbox] { // more elaborated to raise specificity
      margin-top: 3px;
      margin-right: 10px;
    }
  }

  .asterisk-explanation {
    display: block;
    margin: 23px 4px 0px 8px;
    text-align: right !important;
  }

  .primary-auth {
    display: flex;
    flex-direction: column;
  }

  .auth-footer {
    order: 1;
  }

  a[data-se=needhelp] {
    display: none;
  }

  ul#help-links-container {
    text-align: center;
  }
}
</style>
